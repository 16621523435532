.middle h2 {
	padding: 1rem;
	font-size: 1.5rem;
	text-align: center;
}
.middle p {
	padding: 0.2rem;
	font-size: 14px;
	text-align: center;
}
.logo {
	width: 30vw;
}

.wrapper {
	margin-top: 10px;
	margin-bottom: 10px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	width: 90vw;
	/* height: 100vh; */
	min-height: 100vh;
}

.start__button {
	margin-bottom: 1rem;
	width: 45vw;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	background-color: #ed6174;
	color: #fff;
}

.start__button:active {
	color: #fff;
	background-color: #f54269;
	transform: scale(0.95);
	transition: 10ms;
	border: none;
}

.top {
	margin: 15vh 0 0;
}

.middle {
	height: 25vh;
}

.icon {
	margin-left: 6px;
}

.copy__button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.header {
	font-weight: bolder;
}

@media screen and (min-width: 768px) {
	.wrapper {
		width: 100vw;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 10px;
	}
	.container {
		width: 768px;
		box-sizing: border-box;
		background: white;
		/* border-radius: 12px; */
	}
	.logo {
		width: 10vw;
	}

	.start__button {
		width: 20vw;
	}
}
