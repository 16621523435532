:root {
  --content-width: 975px;
}
body {
  /* background: #edf7ff; */
}
.hidden {
  display: none;
}

.App {
  text-align: left;
}

.App-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Hind', sans-serif;
  background: #fff;
  color: #444444;

}
.App-header a { color: black; }
.header-wrap {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: row;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.m-menu {
  width: 64px;
  height: 64px;
}
.m-menu .m-menu-toggler {
  width: 48px;
  height: 48px;
  margin: 8px;
  font-size: 18px;
  border: none;
  background: none;
  outline:none;
}

.header-logo {
  text-align: center;
  width: calc(100% - 74px);
}
.header-logo h1 {
  padding: 0;
  margin: 12px;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 6px;
  font-family: 'Hind', sans-serif;
  /* font-family: 'Hind', 'Didact Gothic', 'Mulish', sans-serif; */
  color: #666666;
}
.App-logo {
  height: 36px;
  pointer-events: none;
}

.App-header .header-nav {
  width: 100%;
  height: calc(100% - 64px);
}
.App-header .header-nav.collapsed {
  display: none;
}

/* Side Menu */
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 48px;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-item {
  padding: 18px 8px;
}

.main-content {
  margin-top: 64px;
  min-height: calc(100vh - 120px);
}

.modal-content {
  margin-top: 64px;
  /* background: #edf7ff; */
}

/* .main-content .main-cover-img {
  width: calc(100% - 18px);
  height: calc(100%);
  margin: 9px;
  background: url('/main_01.jpg');
  background-size: cover;
  background-repeat: no-repeat;
} */

footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #888888;
}
.footer-content {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footer-content .sns-button {
  flex-grow: 1;
  margin: 0 8px;
  text-align: right;
}
.footer-content .copyright {
  flex-grow: 2;
  margin: 0 8px;
  text-align: left;
}

@media (min-width: 975px) {
  .App-header {
    flex-direction: column;
  }
  .header-logo {
    text-align: center;
    width: var(--content-width);
    margin: 0;
  }
  .header-logo h1 {
  letter-spacing: 9px;
  }
  .m-menu {
    margin-top: 7px;
  }
  .header-wrap {
    width: var(--content-width);
    height: 72px;
    text-align: left;
    flex-direction: row-reverse;
  } 
  .App-header .header-nav {
    width: calc(var(--content-width) - 18px);
    height: 48px;
    padding: 0 6px;
    /* margin-top: 28px; */
  }
  .header-logo h1 {
    font-size: 36px;
    text-align: left;
    margin-top: 16px;
  }
  .navbar-nav {
    padding: 0; margin: 0;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-end;
    
  }
  .navbar-nav .nav-item {
    padding: 6px 12px;
  }
  .main-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .main-content {
    margin-top: 112px;
    width: var(--content-width);
    min-height: calc(100vh - 210px);
  }
  .footer-content {
    width: var(--content-width);
  }
  .modal-content {
    margin-top: 112px;
    width: var(--content-width);
    min-height: calc(100vh - 210px);
  }
  section.download h2.section-heading {
    font-size: 58px;
  }
}


/* === artworks list view =========================== */
.artworks .pub-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.artworks .list-item {
    position: relative;
    flex: 1 0 1;
    margin: 11px;
    cursor: pointer;
    width: calc(var(--content-width) / 3 - 22px);
    height: calc(var(--content-width) / 3 - 22px);
    color: #444444;
    background: white;
}
.artworks .list-item a {
    text-decoration: none;
    color: #444444;
}
.artworks .list-item .item-img {
    position: relative;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.artworks .list-item .item-img img {
  width: calc(var(--content-width) / 3 - 22px);
  height: calc(var(--content-width) / 3 - 22px);
    object-fit: cover;
}
.artworks .list-item .item-text {
    justify-content: flex-start;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}
.artworks .list-item .item-text .sub-title {
    font-size: 1.1em; 
    font-weight: bold;
}

.artworks .ArtworkHeader .ArtworkHead-image, .artworks .ArtworkHeader .ArtworkHead-text {
  display: inline-block;
}
.artworks .ArtworkHeader {
  padding: 8px 8px;
  width: var(--content-width);
}
.artworks .ArtworkHeader .ArtworkHead-image img {
  width: 220px;
  border-radius: 50%;
}
.artworks .ArtworkHeader .ArtworkHead-text {
  width: calc(100% - 220px);
  padding-left: 30px;
}
.artworks .ArtworkHeader .ArtworkHead-text .description {
  font-size: 1em;
  color: #aaaaaa;
}
.artworks .ArtworkHeader {
  border-bottom: 3px solid #eee;
  margin-bottom: 18px;
}

@media (max-width: 975px) {
  .artworks .pub-list {
    width: 100vw;
    margin: 0 0.8vw;
  }
  .artworks .list-item {
    width: 31vw;
    height: 31vw;
    margin: 0.8vw;
  }
  .artworks .list-item .item-img img {
    width: 31vw;
    height: 31vw;
  }
  .artworks .list-item .item-text {
    font-size: 11px;
  }
  .artworks .ArtworkHeader {
    width: 100vw;
  }
  .artworks .ArtworkHeader .ArtworkHead-image img {
    width: 160px;
  }
  .artworks .ArtworkHeader .ArtworkHead-text {
    width: calc(100% - 160px);
    padding-left: 8px;
    vertical-align: middle;
  }
  .artworks .ArtworkHeader .ArtworkHead-text .description {
    font-size: 0.9em;
  }
}
/* === artwork item view =========================== */
.artworks .item-view {
  display: block;
  margin: 0 12px;
}
.artworks .item-view .work-title {
  padding-left: 0;
  margin-top: 76px;
}
.artworks .works-img-content {
  width: calc(100vw - 24px);
  height: calc(100vw - 24px);
  background-color: rgba(0, 0, 0, 0.1);
}
.artworks .slick-slide .book-img img {
  margin: 0 auto;
  max-height: calc(100vw - 24px);
}
.artworks .item-view .work-title h3 {
  font-size: 18px;
}
@media (min-width: 992px) {
  .artworks .item-view {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
  .artworks .works-img-content {
    width: 600px;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .artworks .slick-slide .book-img img {
    margin: 0 auto;
    max-height: 600px;
  }
  .artworks .item-view .work-title {
    padding-left: 30px;
    margin-top: 0;
  }
}

/* === toons list view =========================== */
.ToonList .ToonList-element {
  padding: 16px 8px;
  border-bottom: 3px solid #eee;
  margin-bottom: 20px;
}
.ToonList .element-image, .ToonList .element-description {
  display: inline-block;
}
.ToonList .element-image {
  border: 1px solid #eee;
}
.ToonList .element-image img {
  width: 170px;
}
.ToonList .element-description {
  width: calc(100% - 170px - 4px);
  padding-left: 24px;
  vertical-align: middle;
}
.ToonList .element-description .subelement-title {
  font-size: 18px;
  font-weight: bold;
}
.ToonHeader .ToonHead-image img {
  width: 100%;
}
.ToonHeader .ToonHead-text {
  width: 100%;
  padding: 18px 8px;
}
.ToonHeader .ToonHead-text .description {
  color: #aaaaaa;
}
.ToonHeader {
  border-bottom: 3px solid #eee;
  margin-bottom: 18px;
}
.ToonFooter {
  width: 100%;
}
.ToonFooter img {
  width: 100%;
  margin: 28px 0;
}
@media (min-width: 992px) {
  .ToonHeader .ToonHead-image, .ToonHeader .ToonHead-text {
    display: inline-block;
  }
  .ToonHeader {
    padding: 8px 8px;
    width: var(--content-width);
  }
  .ToonHeader .ToonHead-image img {
    width: 360px;
  }
  .ToonHeader .ToonHead-text {
    width: calc(100% - 360px);
    padding-left: 30px;
  }
  .ToonFooter {
    width: var(--content-width);
    text-align: center;
  }
  .ToonFooter img {
    width: 480px;
  }
}
/* === toons item view =========================== */
.toons .main-content {
  padding-bottom: 48px;
}
.toons .works-img-content .work-img img {
  width: 100%;
}
.toons .toons-button-menu {
  margin-top: 28px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.toons .toons-button-menu a {
    width: calc(50% - 6px);
    max-width: 240px;
}
.toons .toons-button-menu .toons-button {
  width: 100%;
  background: #eee;
  border-radius: 4px;
  color: grey;
  font-size: 1.05em;
  text-align: center;
  padding: 8px 0;
}
@media (min-width: 992px) {
  .toons .works-img-content {
    width: var(--content-width);
    text-align: center;
  }
  .toons .works-img-content .work-img {
    margin: 48px;
  }
  .toons .works-img-content .work-img img {
    width: 680px;
  }
  .toons-button-menu {
    width: var(--content-width);
  }
}